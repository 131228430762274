import React, { useState, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useHistory } from 'react-router-dom';

import Login from '../pages/Login';
import Descarga from '../pages/Descarga';
import ResetC from '../pages/ResetC';
import ProtectedRoute from './ProtectedRoute';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../css/temaConfig'



function Routes() {
  const history_2 = useHistory();


  const [user, setUser] = useState(false)

  const handleLogin = async param => {
    await setUser(true);
  }

  const handleLogout = () => {
    //alert('aqui lohoutd');
    setUser(false);
  }





  // useEffect(() => {
  //   if (user === true) {
  //     console.log('entra' + user);
  //     <Redirect to={'/descarga'} />
  //     //history_2.push('/descarga');
  //     //window.location.replace("/descarga");
  //   }
  //   return () => {
  //     //cleanup
  //   }
  // }, [user])


  return (
    <BrowserRouter>
      <Switch>
        <ThemeProvider theme={theme} >
          {/* <Route exact path="/" component={Login}   /> */}
          {/* <ProtectedRoute exact path="/descarga" user={user} component={Descarga} />
          <Route exact path="/:id?" handleLogin={handleLogin} render={props => <Login {...props} user={user.toString()} handleLogin={handleLogin} />} /> */}

          <Route exact path="/" handleLogin={handleLogin} render={props => <Login {...props} user={user.toString()} handleLogin={handleLogin} />} />
          <ProtectedRoute exact path="/descargascp" user={user} component={Descarga} />
        
        </ThemeProvider>

      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
