/******** Reset Password por Correo ******/

import React, {useState} from 'react';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core/';
import { Typography, TextField, Button} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    ButtonAC: {
        marginBottom: '20px',
        margin: theme.spacing(2)
        // width: '45ch',
    },
}));




const ResetPxC = ({ open, onClose, onPickError, onPick,onPickReload }) => {
    
    const classes = useStyles();
    const [dataRxC, setDataRxC] = useState({ email: '' });
    const [disableB, setDisableB] = useState({ disabled: false });
    const urlHash = "https://pro.gpm-it.mx/Monitor/autorizaFacturaBack/N_validaCorreoInicial.php";
    
    const handleChange = async event => {
        const { name, value } = event.target;
        var emailValidar = "";
        emailValidar = name === "email" ? "^[a-z0-9@._-]*$" : "^[a-zA-Z0-9ñ]*$";
        var regexEmail = new RegExp(emailValidar);
        //console.log(value);
        if (regexEmail.test(value)) {
            await setDataRxC({
                ...dataRxC,
                email: value
            })
        }
    };

    const handleClose = async () => {
        onClose();
        await setDataRxC({
                ...dataRxC,
                email: ''
        })
    }

    const validaCampos = async () => {
        var emailValidar =/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        
        console.log(dataRxC.email);
        if (dataRxC.email == "" || typeof dataRxC.email.length == 0  ){
            onPickError("¡Ingresar correo!","No deje valores en blanco");
        }else if ( !(emailValidar.test(dataRxC.email))   ) {
            onPickError("¡Revise sus datos", 'El formato de correo es: correo@ejemplocorreo.com ');
            
        } else {
            ResetearContraCorreo();
        }
    }

    const ResetearContraCorreo = async () => {
        await setDisableB({
                disabled:true,
        })
        
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        try {
            const raw = JSON.stringify({
                "email": dataRxC.email,
                "password": 'pull.m@n',
            });

            const config = {
                method: 'POST',
                body: raw,
                cache: 'default',
                redirect: 'follow',
                headers: myHeaders,
            };
            let res = await fetch(urlHash, config);
            let response = await res.json();
            //alert(`La respuesta es 1: ${JSON.stringify(response)}`);
            //1 - actualizo contraseña:
            if (response == "Debe generar su contraseña") {
                
                handleClose();
                onPickReload(`Para ingresar por primera vez, debe generar su contraseña`);
                
            }else if (typeof response[0].CorreoRespuesta.length !== 0 &&  response[0].CorreoRespuesta == "Correo enviado satisfactoriamente" ) {
                
                handleClose();   //Cerrar el modal de reseteo de contraseña
                onPick(`${JSON.stringify(response[0].CorreoRespuesta)}`,`Se le ha enviado un correo indicando: 'cambio de contraseña' con las indicaciones para cambiar su contraseña, podría encontrarlo en su bandeja de entrada o en su bandeja de correo no deseado.`,"success");
                
                //Borrar el validador
                //limpiarValidador();

            
            } else {
                onPick(`${JSON.stringify(response)}`,'Verifique sus datos',"warning");
            }
        } catch (err) {
            onPick(`${err}`,'Verifique sus datos',"warning");
        }
    }

    const limpiarValidador = async () =>
    {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
    }


    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle id="form-dialog-title">¿Olvidó su contraseña?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography component="subtitle1" variant="subtitle1" color="inherit"  >
                            * Ingrese el correo electrónico registrado en su cuenta:
                        </Typography>
                    </DialogContentText>
                    <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                value={dataRxC.email}
                                fullWidth
                                id="email"
                                label="* Correo"
                                name="email"
                                autoComplete="email"
                                className={classes.textFieldLC}
                                onChange={handleChange}
                                inputProps={{ style: { textTransform: 'lowercase' }, }}
                                InputLabelProps={{ style: { textTransform: 'capitalize' } }}
                                labelWidth={100}
                                autoFocus
                            />
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary" disabled={disableB.disabled}>
                        Cancelar
                    </Button>
                    <Button onClick={validaCampos} variant="contained" color="secondary" className={classes.ButtonAC} disabled={disableB.disabled}>
                        Solicitar Cambio de Contraseña
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ResetPxC;