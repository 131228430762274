import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import md5 from 'md5';



const useStyles = makeStyles((theme) => ({
    textFieldC: {
        marginTop: '20px',
        textTransform: 'lowercase',
        width: '100%',
        margin: theme.spacing(0)
        // width: '45ch',
    },
    ButtonAC: {
        marginBottom: '20px',
        margin: theme.spacing(2)
        // width: '45ch',
    },
}));

const ResetC = ({ open, onClose, handleLogin, onPickError }) => {
    const [newData, setNewData] = React.useState({ passwordReset: '', showPassword: false });
    const classes = useStyles();
    const history = useHistory();
    const url = "https://pro.gpm-it.mx/Monitor/autorizaFacturaBack/N_cifrado.php";


    const handleChange = async event => {
       const { name, value } = event.target;
        var passwordV = "";
        passwordV =/^[a-z0-9ñ]*$/i;

        console.log(`nuevo valor : ${value}`);
        if (passwordV.test(value)) {
            await setNewData({
                ...newData,
                [name]: value
            })
        }
    };


    const handleClickShowPasswordR = async () => {
        await setNewData({
            ...newData,
            showPassword: !newData.showPassword
        })
    };

    const handleMouseDownPasswordR = (event) => {
        event.preventDefault();
    };

    const handleClose = () => {
        onClose();
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    //Validar Contraseña
    const validaCampos = async () => {
        var passwordValidar = /^(?=.*[a-zñ])(?=.*[A-ZÑ])(?=.*[0-9])\S{8,30}$/;
        if ( newData.passwordReset == "" || typeof newData.passwordReset.length == 0 ){
            onPickError("¡Debe ingresar su nuevo password!","Por favor, No deje valores en blanco");
        }else if ( !(passwordValidar.test(newData.passwordReset)) ||  ( newData.passwordReset.length > 0 && newData.passwordReset.length < 8 ) || newData.passwordReset.toUpperCase().includes('PULLMAN') || newData.passwordReset.toUpperCase().includes('PASSWO') ) {
            onPickError('¡Revise sus datos','OJO: La contraseña debe ser de 8 caracteres y no debe contener la palabra "pullman", al menos un numero, al menos una minúscula y al menos una mayúscula (ejemplo: Passwo01)');
        } else {
            actualizaPassword();
        }
    }


    //Actualiza contraseña
    const actualizaPassword = async () => {
        try {
            let responsePass2 = "";
            responsePass2 = md5(newData.passwordReset);
            const raw = JSON.stringify({
                "email": sessionStorage.getItem('email'),
                "trabid": sessionStorage.getItem('trabid'),
                "password": responsePass2,
            });
            const config = {
                method: 'POST',
                body: raw,
                cache: 'default',
                redirect: 'follow',
                headers: myHeaders,
            };
            let res = await fetch(url, config);
            let response = await res.json();

            //alert(` ${JSON.stringify(response)}`);

            if (response.length > 0) {

                console.log(`resultado ${raw}`);
                //alert(`cerrar el modal ${response}`)
                handleClose();   //Cerrar el modal de reseteo de contraseña
                //onPickError(`Ingrese con su nueva contraseña`);
                //handleNomina(response);
                await handleLogin();
                history.push("/descargascp")

                //history.push("/:id?/descarga")
                //window.location.replace("https://descargas.gpm-it.mx/descarga");
            } else {
                alert("No hay recibos de Nómina pendientes");
            }
        } catch (err) {
            alert(err);
        }
    }



    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle id="form-dialog-title">Cambio de Contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        <Typography component="subtitle1" variant="subtitle1" color="inherit"  >
                            * Registre una nueva contraseña para ingresar a la descarga de sus recibos de nómina, la cual debe contener mínimo 8 caracteres:
                            </Typography>


                    </DialogContentText>
                    <Typography component="subtitle2" variant="subtitle2" color="primary"  >
                        Nota: Da Clic en el <IconButton><VisibilityOff /> </IconButton> dentro del campo para verificar la contraseña .
                            </Typography>

                    <FormControl className={classes.textFieldC} variant="outlined">
                        <InputLabel htmlFor="passwordReset" style={{ textTransform: 'capitalize' }}> * Nueva contraseña</InputLabel>
                        <OutlinedInput
                            id="passwordReset"
                            name="passwordReset"
                            type={newData.showPassword ? 'text' : 'password'}
                            value={newData.passwordReset }
                            autoFocus
                            onChange={handleChange}
                            inputProps={{ maxLength: 30 }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordR}
                                        onMouseDown={handleMouseDownPasswordR}
                                        edge="end"
                                    >
                                        {newData.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={200}
                            fullWidth
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">
                        Cancel
          </Button> */}
                    <Button onClick={validaCampos} variant="contained" color="secondary" className={classes.ButtonAC}>
                        Registrar Contraseña
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ResetC;