import React, { useState, useEffect } from 'react';
import { useHistory, Redirect,useLocation } from 'react-router-dom';
import { Paper, Button, TextField, Typography, Link, FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Cookies from 'universal-cookie';
import swal from '@sweetalert/with-react';
import LogoP from '../img/enviospullman.svg';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ResetC from './ResetC';
import md5 from 'md5';
import ResetPxC from './ResetPxC';

import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
// import LockIcon from '@material-ui/icons/Lock';
// import candado from '../img/candado.svg';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary"  style={{fontSize:"11px"}}>
            {'Copyright ©'}
            {/* <Link color="inherit" href="https://pullman.mx/">Pullman de Morelos</Link> */}
            {''}{new Date().getFullYear()}{''}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paperStyle: {
        height: '75vh',
        //height: '500px',
        minHeight: '350px',
        width: '400px',
        minWidth: '200px',
        margin: '20px auto',
        padding: '30px',
        // marginTop: '10px',
        position: 'relative',

    },
    root: {
        width: '100%',
        minHeight: '100vh',

        padding: theme.spacing(0),
        textAlign: 'center',
        flex: '1 0 auto',
        margin: theme.spacing(0),
        paddingTop: '3%',
    },
    image: {
        width: '100%',
        backgroundImage: 'url(https://descargas.gpm-it.mx/img/fondo_cartasPorte.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        // background: '#bdbdbd',  /* fallback for old browsers */
        // background: '-webkit-linear-gradient(to bottom, #bdbdbd, #fff)',  /* Chrome 10-25, Safari 5.1-6 */
        // background: 'linear-gradient(to bottom, #bdbdbd, #fff)', /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

        margin: theme.spacing(0),
        padding: theme.spacing(0),
        flex: '1 0 auto',
    },
    // contain: {
    //     backgroundColor: 'green',
    //     width: '100%',
    // },
    // paper: {
    //     marginTop: theme.spacing(8, 4),
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     backgroundColor: '#333 !important',
    // },
    //const paperStyle = { height: '70vh', width: 400, margin: "20px auto", padding: 25, }

    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#757575',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textFieldLC: {
        marginTop: '15px',
        textTransform: 'lowercase',
    },
    textFieldC: {
        marginTop: '20px',
        textTransform: 'lowercase',
        width: '100%',
        margin: theme.spacing(0)
    },

}));

const url = "https://pro.gpm-it.mx/descargarCartasP/N_bd_auth.php";

const urlVUsuario = "https://pro.gpm-it.mx/Monitor/autorizaFacturaBack/N_validaUsuarioInicial.php";
const urlCifrado = "https://pro.gpm-it.mx/Monitor/autorizaFacturaBack/N_codifica_cadena.php";
const urlvalVerificar = "https://pro.gpm-it.mx/Monitor/autorizaFacturaBack/N_verificaVal.php";


const cookies = new Cookies();

const Login = (props) => {
    const { handleLogin, user } = props;
    const classes = useStyles();
    const [dataUser, setDataUser] = useState({ cve: '', bus: '', idV:''});
    const [openR, setOpenR] = React.useState(false); // Para Modal Reset Password
    const [openRPxC, setOpenRPxC] = React.useState(false); // Para Modal Reset Password
    

    const history = useHistory();

    // let { id = '' } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    const myHeaders = new Headers();
    //myHeaders.append("Authorization","Basic cHJlOnByZS4=");
    myHeaders.append("Content-Type", "application/json");
    //myHeaders.append("Cookie", "__cfduid=dddd4fb5f5547b09f4f0211f17bdfc5661618954594");
    


    //usar un useEffect para activar el reseteo por contraseña
    useEffect(() => {
        const idValidar=/^[a-zA-Z0-9]{30,60}$/;
        if (idValidar.test(id)) {
            const fetchResource = async () => {
                try {
                    const raw = JSON.stringify({
                        "id": id,
                    });
                    const config = {
                        method: 'POST',
                        body: raw,
                        cache: 'default',
                        redirect: 'follow',
                        headers: myHeaders,
                        //credentials: 'include',
                        // mode: 'cors',
                    };
                    let res = await fetch(urlvalVerificar, config);
                    let response = await res.json();

                    
                    if (response === "0") {    //ir a comparar RFC
                        onPickReload(`Su validación caducó`, `Vuelva a realizar el proceso para cambiar su contraseña`,'error');
                        //iniciarSesion(dataUser.password);
                        //onPick(`Su validación caducó`);
                    } else if (response[0].RFC.length > 0){
                        
                        sessionStorage.setItem('RFC', response[0].RFC);
                        sessionStorage.setItem('Nombre', response[0].Nombres);
                        sessionStorage.setItem('Paterno', response[0].Paterno);
                        sessionStorage.setItem('email', response[0].Email);
                        sessionStorage.setItem('trabid', response[0].TrabID);
                        sessionStorage.setItem('EmpresaID', response[0].EmpresaID);
                        
                        
                        handleClickOpenR();
                        //await handleLogin();
                        //history.push("/descarga")
                            
                    }
                        
                } catch (err) {
                    onPick(err);
                }
            }
            fetchResource();
        } else {
            const locale = window.location.hostname;

            //window.location.replace('https://descargas.gpm-it.mx/');
        }

    }, [id]);



     


    const handleClickOpenR = () => {
        setOpenR(true);
    };

    const handleCloseR = () => {
        setOpenR(false);
    };

    /* Funciones para activar el Modal ResetPxC */
    const handleClickOpenRPxC = () => {
        setOpenRPxC(true);
    };

    const handleCloseRPxC = () => {
        setOpenRPxC(false);
    };

    const onPick = value => {
        swal(
            {
            title: `${value}`, 
            text: `Revise sus datos y/o notifique al área de Recursos Humanos`,
            icon: `warning`,
            button: "Aceptar",
            dangerMode: true,
            })
            .then((value) => {
                window.location.reload();
            });
    }

    const onPickG = (value,subti,icon) => {
        swal({
            title: `${value}`, 
            text: `${subti}`,
            icon: `${icon}`,
            button: "Aceptar",
            dangerMode: true,
        })
            .then((value) => {
                //window.location.reload();
            });
    }

    const onPickError = (value, subti) => {
        swal({
            title: `${value}`, 
            text: `${subti}`,
            icon: "error",
            button: "Aceptar",
            dangerMode: true,
        })
            .then((value) => {
                //window.location.reload();
            });
    }

    const onPickErrorUsuario = (value, subti) => {
        swal({
            title: `${value}`, 
            text: `${subti}`,
            icon: "error",
            button: "Aceptar",
            dangerMode: true,
        })
            .then((value) => {
                window.location.reload();
            });
    }

    const onPickReload = (value, subti,icon) => {
        swal({
            title: `${value}`, 
            text: `${subti}`,
            icon: `${icon}`,
            button: "Aceptar",
            dangerMode: true,
        })
            .then((value) => {
                window.location.replace('https://descargas.gpm-it.mx/');
            });
    }


    const handleChange = async event => {
        const { name, value } = event.target;
        console.log(value);
        //if (regexEmail.test(value)) {
            await setDataUser({
                ...dataUser,
                [name]: value
            })
        //}
    };


    


    const validaCampos = async () => {
        //alert(` los datos del bus son: ${dataUser.bus} y la clave del operador: ${dataUser.cve} `);

        if (dataUser.cve == "" || dataUser.bus == "" || typeof dataUser.bus.length == 0 || typeof dataUser.cve.length == 0  ){
            onPickError("¡Debe ingresar correo y numero de autobús!","No deje valores en blanco");
        } else {
            iniciarSesion();
        }
    }

    // const verificaPass = async () => {        
    //     let responsePass = "";
    //     responsePass = md5(dataUser.password);
    //     try {
    //         const raw = JSON.stringify({
    //             "email": dataUser.email,
    //             "password" : "pullman",
    //         });

    //         const config = {
    //             method: 'POST',
    //             body: raw,
    //             cache: 'default',
    //             redirect: 'follow',
    //             headers: myHeaders,
    //         };
    //         let res = await fetch(urlVUsuario, config);
    //         let response = await res.json();

    //         if (response === "0") {    //ir a comparar RFC
    //             console.log(`ir a iniciar sesion con el rfc`);
    //             iniciarSesion(dataUser.password);
    //         } else if (response === "1") {   // Ir a verificar la contraseña
    //             await setDataUser({
    //                 ...dataUser,
    //                 password: responsePass
    //             })
    //             iniciarSesion(responsePass);
    //         } else {
    //             onPickError(response,`Verifique sus datos`);
    //         }
            
    //     } catch (err) {
    //         onPickError(err,`Verifique sus datos`);
    //     }
    // }



    const iniciarSesion = async () => {
        try {
            const raw = JSON.stringify({
                "bus": dataUser.bus,
                "cve": dataUser.cve
            });
            const config = {
                method: 'POST',
                body: raw,
                cache: 'default',
                redirect: 'follow',
                headers: myHeaders,
                // credentials: 'include',
                // mode: 'cors',
            };
            let res = await fetch(url, config);
            let response = await res.json();


            if ( response.estatus === 0 ) {
                onPickErrorUsuario("¡No encontramos datos!",`Verifique su número de autobús y su clave`);
            }else if ( response[0].ID != '' ) {

                sessionStorage.setItem('ID', response[0].ID);
                sessionStorage.setItem('ID_OPERADOR', response[0].ID_OPERADOR);
                sessionStorage.setItem('ID_AUTOBUS', response[0].ID_AUTOBUS);
            

                await handleLogin();
                history.push("/descargascp")
             }else{
                onPickErrorUsuario("¡Error!",`Verifique sus datos`);
             }
        } catch (err) {
            onPick(err);
        }
    }

    

    const handleClickShowPassword = async () => {
        await setDataUser({
            ...dataUser,
            showPassword: !dataUser.showPassword
        })
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    return (
        <React.Fragment>
            <ResetPxC open={openRPxC} onClose={handleCloseRPxC} onPickError={onPickError} onPick={onPickG} onPickReload={onPick}/>
            <ResetC open={openR} onClose={handleCloseR} handleLogin={handleLogin} onPickError={onPickError}/>
            <Container container className={classes.image} maxWidth={false} >
                <Container item className={classes.root} maxWidth={false} >
                         
                    <CssBaseline />
                    {/* <img src={LogoP} alt="pullman" width={'150px'} />    */}


                    <Paper elevation={10} className={classes.paperStyle}  >
                        <Grid align='center'>
                            <img src={LogoP} alt="pullman" width={'200px'}  style={{position: 'absolute', bottom: '10px', left: '25%', top:'-3%' }} />
                            <Typography variant="h3" gutterBottom style={{ marginTop: '90px',fontSize: '30px', fontWeight: '600', marginBottom: '10px' }}>
                                Descarga de Embarque Cartas Porte
                            </Typography>
                        </Grid>
                        
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                value={dataUser.cve}
                                fullWidth
                                id="cve"
                                label="* Clave operador"
                                name="cve"
                                autoComplete="cve"
                                className={classes.textFieldLC}
                                onChange={handleChange}
                                inputProps={{ style: { textTransform: 'lowercase' }, }}
                                InputLabelProps={{ style: { textTransform: 'capitalize' } }}
                                labelWidth={10}
                            />

                            <TextField
                                variant="outlined"
                                value={dataUser.bus}
                                //type="number"
                                type="text"
                                fullWidth
                                id="bus"
                                label="* Número Autobús"
                                name="bus"
                                autoComplete="bus"
                                className={classes.textFieldLC}
                                onChange={handleChange}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*'  }}
                                InputLabelProps={{ style: { textTransform: 'capitalize' } }}
                                labelWidth={8}
                                autoFocus
                            />


                            

                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={validaCampos}
                                style={{textTransform: 'none'}}
                            >
                                Aceptar
                        </Button>
                            <p style={{ position: 'absolute', bottom: '-6px', paddingLeft: '33%', marginTop:'20px' }}>
                                <Copyright />
                            </p>

                            <Grid container align="center">
                                    <Grid item xs>
                                    <Link variant="body2" onClick={handleClickOpenRPxC}>
                                            {/* {"¿Olvidó la contraseña?"} */}
                                        </Link>
                                    </Grid>
                                </Grid>

                        </form>
                    </Paper>
                </Container >
            </Container >
        </React.Fragment>
    )
}

export default Login
