import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import DialogLoding from './Dialog';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DescriptionIcon from '@material-ui/icons/Description';



function preventDefault(event) {
    event.preventDefault();
}



const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: "rgba(119, 132, 149,0.06) !important",
      },

    palette: {
        primary1Color: "rgba(183, 28, 28, 0.07)",
        primary2Color: "rgba(158, 158, 158, 0.05)",
        primary3Color: "rgba(158, 158, 158, 0.05)",
        accent1Color: "rgba(158, 158, 158, 0.05)",
        accent2Color: "rgba(158, 158, 158, 0.05)"
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    link2: {
        verticalAlign: 'center',
        textAlign: 'center !important',
    },


    root: {
        '&$disabled': {
          color: 'white',
        },
      },
}));

const TablaNomina = ({ anio, flag }) => {
    const classes = useStyles();
    const urlRed = `${process.env.REACT_APP_BACKEND}paginacion.php`;
    const url = `${process.env.REACT_APP_FACTURAS}php/N_descargarCartasPorte.php`;

    const [selectedIndex, setSelectedIndex] = useState([]);
    const [cartasPorte,setCartasPorte] = useState([]);
    const [totalPaginas,setTotalPaginas] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Cantidad de elementos por página
    const totalPages = 4;

    const [mensaje,setMensaje]= useState("Buscando recibos ...") ;
    const [open, setOpen] = useState(false);

    const handleClickOpenClose = async (estatusModal) => {
        await setOpen(estatusModal);
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json;charset=UTF-8");
    //myHeaders.append("Authorization", "Basic cHJlOnByZS4=");



    const numRegistros = async() =>{
            const raw = JSON.stringify({
            "cve" : sessionStorage.getItem('ID_OPERADOR'),
            "bus" : sessionStorage.getItem('ID_AUTOBUS'),
            "pagina": currentPage,
            "tarea": "cuantosRegistros"
        });
    
        const config = {
            method: 'POST',
            body: raw,
            cache: 'default',
            redirect: 'follow',
            headers: myHeaders,
        };

        await fetch(urlRed,config)
        .then(async (response)  => await response.json())
         .then( async (dataRes) => 
            await setTotalPaginas(dataRes)
        ).catch(e => {
            console.error(`Error: ${e.message}`);
        }); 

    }


    const obtenerCartasPorte = async() => {
        const raw = JSON.stringify({
            "cve" : sessionStorage.getItem('ID_OPERADOR'),
            "bus" : sessionStorage.getItem('ID_AUTOBUS'),
            "pagina": currentPage,
            "tarea": "paginacion"
        });
    
        const config = {
            method: 'POST',
            body: raw,
            cache: 'default',
            redirect: 'follow',
            headers: myHeaders,
        };

        await fetch(urlRed,config)
        .then(async (resp)  => await resp.json())
         .then( async (data) => 
            await setCartasPorte(data),
            
            setTimeout(() => {
                handleClickOpenClose(false)
              }, 1000)
        
        ).catch(e => {
            console.error(`Error: ${e.message}`);
        }); 
    }


    // const myHeaderDescargar= new Headers();
    // myHeaderDescargar.append("Content-Type", "application/json");

    // cve_comproabate, type=1pdf,2xml,nombre del archivo, index de la página seleccionada.
    const descargar = async (cve,type,nombre_arch, index) => {
        //activando el selected
        // setSelectedIndex([
        //     ...selectedIndex,
        //     index
        // ]);

        //alert(`en descarga ${cve} tipo: ${type} nombre: ${nombre_arch}, indice: ${index} `);

        const raw2 = JSON.stringify({
            "cve" : cve,
            "type": type,
        });

        const configDescargar = {
            method: 'POST',
            body: raw2,
            cache: 'default',
            redirect: 'follow',
            headers: myHeaders,
        };

        try {

            let res = await fetch(url, configDescargar);
            let response = await res.blob();
            let newBlob;let nameArchivo;

            if(type == 1 || type == 3 ){

                newBlob = new Blob([response], {type: "application/pdf"})
                nameArchivo =`${nombre_arch}.pdf`;
            }else{
                newBlob = new Blob([response], {type: "application/xml"})
                nameArchivo =`${nombre_arch}.xml`;
            }

            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            link.href = data;

            if(type == 1){

                console.log(` blank `);
                
                let newWindow = window.open(data,"_blank");


                if( !newWindow || newWindow.closed || typeof newWindow.closed=='undefined')
                {
                    console.log(`descargando... `);
                    link.download = nameArchivo;
                    link.click();
                }else{
                    newWindow.location = data;
                }


                //Abre el PDF
                //if (!window.open(data,"_blank", 'width=800,height=500') )
                //{
                    //link.setAttribute("target","_blank" );
                    //link.download = nameArchivo;
                    //link.click();
              // }
            }else{
                //Descarga el XML
                link.download = nameArchivo;
                link.click();
            }


            setTimeout(function(){
                window.URL.revokeObjectURL(data);
            }, 50000);


        } catch (err) {
            alert(err);
        }
    }




    useEffect(() => {
        //Abrir Modal Loading...
        handleClickOpenClose(true);

        //Obtener el numero de registros por operador
        numRegistros();

        //Devolver los resultados de las facturas a consultar
        obtenerCartasPorte();

        //Limpiar el index de los seleccionados
        setSelectedIndex([]);

        return () => {
            // cleanup
        }
    }, [anio])


    // useEffect(()=>{
    //     alert(`${JSON.stringify(totalPaginas)}`);
    // },[totalPaginas])


    const handlePageChange = (page) => {
        handleClickOpenClose(true);         //abre modal loading
        setCurrentPage(page);               //Establece el no. de página consultada
    };


    useEffect(() => {
        // Obteniendo las cartas porte por por página seleccionada al cambiar de pagina
        obtenerCartasPorte();
    }, [currentPage]);
    


    return (
        <React.Fragment>
            <DialogLoding open={open} onClose={handleClickOpenClose} />
            <Title > Relación de cartas porte por embarque  </Title>
            <h3 style={{margin:'0px' }}>Operador: {sessionStorage.getItem('ID_OPERADOR')}, Autobús: {sessionStorage.getItem('ID_AUTOBUS')}  </h3>
            <h3 style={{margin:'0px' }}>Total de Paquetes:  {totalPaginas.totalRegistros}</h3>
            <Typography component="subtitle2" variant="subtitle2" color="inherit" noWrap className={classes.title}>
                Para visualizar mas registros de click en el número de página en la parte de abajo.
            </Typography>
            <hr />

            <Table size="small" >
                <TableHead>
                    <TableRow style={{ background:'rgba(119, 132, 149,0.2)'}} >
                        <TableCell align="center" style={{ fontWeight:900}} >Comprobante</TableCell>
                        <TableCell align="center" style={{ fontWeight:900}} >Número Carta Porte</TableCell>
                        <TableCell align="center" style={{ fontWeight:900}} >Descarga PDF</TableCell>
                        <TableCell align="center" style={{ fontWeight:900}} >Descarga XML</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                     {cartasPorte.length > 0 ?
                    
                        cartasPorte.map((row, index) => (
                            // selected={ row.NO_DESCARGAS != null  || selectedIndex.includes(index) }  classes={{selected: classes.selected }} 
                            <TableRow key={row.CVE_COMPROBANTE}    >
                                <TableCell align="center" >{row.CVE_COMPROBANTE}</TableCell>
                                <TableCell align="center">{row.FOLIO}</TableCell>
                                <TableCell align="center">
                                    {/* {row.RUTA_ARCHIVO} */}
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => descargar(row.CVE_COMPROBANTE,1,row.NOMBRE_ARCHIVO, index)}
                                    >
                                        <PictureAsPdfIcon />
                                    </Button>
                                    
                                </TableCell>
                                <TableCell align="center">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => descargar(row.CVE_COMPROBANTE,2,row.NOMBRE_ARCHIVO,index)}
                                    >
                                        <DescriptionIcon />
                                </Button>
                                </TableCell>
                            </TableRow>
                        ))
                        : 
                        // <CircularProgress disableShrink />
                        <b>{mensaje}</b>


                    } 
                </TableBody>
                
            </Table>

            <div>
                    <nav class="pagination-container">
                        <Button className="pagination-button" id="prev-button" title="Previous page" aria-label="Previous page" >&lt;</Button>
                        <div id="pagination-numbers">
                            {Array.from({ length: totalPaginas.totalPaginas }, (_, index) => (
                                <button
                                    className="pagination-number"
                                    aria-label={`Page${index}`}
                                    page-index={index}
                                    //variant="outlined" size="small"
                                    key={index}
                                    onClick={() => handlePageChange(index + 1)}

                                    style={{
                                        //fontWeight: currentPage === index + 1 ? 'bold' : 'normal',
                                        fontColor: currentPage === index + 1 ? '#ffffff' : '#333',
                                        background: currentPage === index + 1 ? '#2e40a4e3' : 'none',
                                    }}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                        <Button className="pagination-button" id="next-button" title="next-page" aria-label="next-page" > &gt; </Button>
                    </nav>
              

            </div>
            <div className={classes.seeMore}>
                <Link color="primary" href="#" onClick={preventDefault}>
                    {/* See more orders */}
                </Link>
            </div>
        </React.Fragment >
    );
}

export default TablaNomina;