import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { FormControl, OutlinedInput, InputLabel, InputAdornment, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import md5 from 'md5';


const useStyles = makeStyles((theme) => ({
    textFieldC: {
        marginTop: '20px',
        textTransform: 'lowercase',
        width: '100%',
        margin: theme.spacing(0)
        // width: '45ch',
    },
    ButtonAC: {
        marginBottom: '20px',
        margin: theme.spacing(2)
        // width: '45ch',
    },
}));

const CambiarC = ({ open, onClose, onPickError,onPick }) => {
    const [Data, setNewData] = React.useState({ password: '', NewPass : '',showPasswordA: false, showPasswordN: false });
    const classes = useStyles();
    // const history = useHistory();
    const url = "https://pro.gpm-it.mx/Monitor/autorizaFacturaBack/N_verificaPassword.php";

   



    //Valida los caracteres y los agrega a state
    const handleChange = async event => {
       const { name, value } = event.target;
        var passwordV = "";
        passwordV =/^[a-z0-9ñ]*$/i;

        console.log(`nuevo valor : ${value}`);
        if (passwordV.test(value)) {
            await setNewData({
                ...Data,
                [name]: value
            })
        }
    };


    const handleClickShowPasswordA = async () => {
        //console.log(` El input tiene el nombre del campo es 1: `);
        await setNewData({
            ...Data,
            showPasswordA: !Data.showPasswordA
        })
    };

    const handleClickShowPasswordN = async () => {
        await setNewData({
            ...Data,
            showPasswordN: !Data.showPasswordN
        })
    };

    const handleMouseDownPasswordR = (event) => {
        event.preventDefault();
    };

    const handleClose = async () => {
        onClose();
        await setNewData({
            ...Data,
            password: '',
            NewPass:  ''
        })
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");



    //Validar Contraseña al dar click en nueva contraseña
    const validaCampos = async () => {

        //console.log(` El input tiene el nombre del campo es 1:${JSON.stringify(Data)} `);
        var passwordValidar = /^(?=.*[a-zñ])(?=.*[A-ZÑ])(?=.*[0-9])\S{8,30}$/;
        if ( Data.password == "" || typeof Data.password.length == 0 || Data.NewPass == "" || typeof Data.NewPass.length == 0    ){
            onPickError("¡Debe ingresar contraseña actual y nueva contraseña!","Por favor, No deje valores en blanco");
        } else if ( Data.password === Data.NewPass ) {
            onPickError('Las contraseñas son iguales','Por favor revise los datos ingresados');
         }else if ( !(passwordValidar.test(Data.password)) || !(passwordValidar.test(Data.NewPass)) ||  ( Data.password.length > 0 && Data.password.length < 8 ) ||  ( Data.NewPass.length > 0 && Data.NewPass.length < 8 ) || Data.password.toUpperCase().includes('PULLMAN') || Data.NewPass.toUpperCase().includes('PULLMAN') || Data.password.toUpperCase().includes('PASSWO') || Data.NewPass.toUpperCase().includes('PASSWO') ) {
            onPickError('¡Revise sus datos','OJO: La contraseña debe ser de 8 caracteres y no debe contener la palabra "pullman", al menos un numero, al menos una minúscula y al menos una mayúscula (ejemplo: Passwo01)');
        } else {
            actualizaPassword();
            //alert(`actualizar`);
        }
    }



    //Actualiza contraseña
    const actualizaPassword = async () => {
        try {
            let passwordA = "",newPassword="";
            passwordA = md5(Data.password);
            newPassword =  md5(Data.NewPass)

            const raw = JSON.stringify({
                "email": sessionStorage.getItem('email'),
                "trabid": sessionStorage.getItem('trabid'),
                "password": passwordA,
                "newpassword" : newPassword,
            });

            console.log(` raw: ${raw}`);
            const config = {
                method: 'POST',
                body: raw,
                cache: 'default',
                redirect: 'follow',
                headers: myHeaders,
            };
            let res = await fetch(url, config);
            let response = await res.json();
            //alert(`La respuesta es: ${JSON.stringify(response)}`);
            //1 - actualizo contraseña:
            if (response === "1") {
                //limpiar el state 
                handleClose();   //Cerrar el modal de reseteo de contraseña
                onPick("Contraseña Actualizada Correctamente","En el siguiente ingreso utilice su nueva Contraseña");
                //Mensaje de se actualizo correctamente su password
            } else {
                onPickError(`${response}`,'Verifique sus datos');
            }
        } catch (err) {
            alert(err);
        }
    }



    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick
                disableEscapeKeyDown>
                <DialogTitle id="form-dialog-title">Cambiar de Contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        <Typography component="subtitle1" variant="subtitle1" color="inherit"  >
                            * Ingrese su nueva contraseña, la cual debe contener 8 caracteres, por lo menos una mayúscula y un numero, no utilice la palabra 'pullman':
                            </Typography>


                    </DialogContentText>
                    <Typography component="subtitle2" variant="subtitle2" color="primary"  >
                        Nota: Da Clic en el <IconButton><VisibilityOff /> </IconButton> dentro del campo para verificar la contraseña .
                            </Typography>
                    

                    <FormControl className={classes.textFieldC} variant="outlined">
                        <InputLabel htmlFor="password" style={{ textTransform: 'capitalize' }}> * Contraseña Actual</InputLabel>
                        <OutlinedInput
                            id="password"
                            name="password"
                            type={Data.showPasswordA ? 'text' : 'password'}
                            value={Data.password }
                            autoFocus
                            onChange={handleChange}
                            inputProps={{ maxLength: 30 }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordA}
                                        onMouseDown={handleMouseDownPasswordR}
                                        edge="end"
                                    >
                                        {Data.showPasswordA ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={200}
                            fullWidth
                        />
                    </FormControl>

                    <FormControl className={classes.textFieldC} variant="outlined">
                        <InputLabel htmlFor="NewPass" style={{ textTransform: 'capitalize' }}> * Nueva contraseña</InputLabel>
                        <OutlinedInput
                            id="NewPass"
                            name="NewPass"
                            type={Data.showPasswordN ? 'text' : 'password'}
                            value={Data.NewPass }
                            onChange={handleChange}
                            inputProps={{ maxLength: 30 }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordN}
                                        onMouseDown={handleMouseDownPasswordR}
                                        edge="end"
                                    >
                                        {Data.showPasswordN ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={200}
                            fullWidth
                        />
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={validaCampos} variant="contained" color="secondary" className={classes.ButtonAC}>
                        Actualizar Contraseña
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CambiarC;